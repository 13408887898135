<script setup>
const slots = useSlots()
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue    : { type: Boolean, default: false },
  titleCenter   : { type: Boolean, default: false },
  contentCenter : { type: Boolean, default: false },
  width         : { type: [String,Number], default: 1200 },
})

const visible = ref(false)

watch(() => props.modelValue, aft => {
  if (aft) {
    visible.value = aft
  } else {
    setTimeout(() => visible.value = aft, 300);
  }
})
</script>

<template>
  <div
    v-if="visible"
    class="dialog"
    :class="{ 'dialog-hide': !modelValue }"
  >
    <div class="dialog-wrapper">
      <div
        ref="dialog"
        class="dialog-content"
        :class="{'dialog-content-center': contentCenter}"
        :style="{maxWidth: typeof width == 'number' ? width+'px' : width}"
      >
        <div class="dialog-header">
          <p
            class="dialog-title"
            :class="{ 'text-center': titleCenter }"
          >
            <slot name="title"></slot>
          </p>

          <button
            type="button"
            class="dialog-btn-close btn btn-circle btn-xs"
            @click="emits('update:modelValue', false)"
          >
            <icon
              icon="mdi:close"
              width="16"
            />
          </button>
        </div>

        <div class="dialog-body">
          <slot></slot>
        </div>

        <div
          class="dialog-footer"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>